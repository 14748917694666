.header-row {
  background-color: rgb(48, 48, 48);
}
.logo {
  font-size: 2.6rem;
  color: rgb(214, 198, 20);
}
.app-heading {
  font-size: 2.6rem;
  color: #0ca045;
  font-weight: 700;
  letter-spacing: 2.1px;
}
.app-heading span.bold {
  color: #719c82;
}
.btn-format {
  border: none;
  outline: none;
  border-radius: 7px;
  font-size: 1.15rem;
  letter-spacing: 0.1rem !important;
  font-family: "Roboto", serif !important;
  width: 190px !important;
}
.flex-row {
  display: flex;
  column-gap: 3.2rem;
}
.feedback {
  max-width: 400px;
  min-width: 310px;
}
.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 400px;
  gap: 4;
}
.card-d-flex {
  display: flex;
  flex-direction: row;

  align-items: start;
  justify-items: start;
}
.d1-flex {
  display: flex;
  flex-direction: row;
  max-width: 60%;
  gap: 10;
  align-items: center;
  justify-content: space-evenly;
}
.d1-flex-inner {
}
.img-name {
  width: 30px;
  height: 40px;
}
.w-150 {
  width: 140px;
  height: 70px;
  margin: 5px 5px;
}
.p-studentname {
  font-size: 0.89rem;
  margin-left: 8px;
  text-align: left;
}
